<template>
    <div class="Tabs">
        <div class="tab left" :class="{ active: selected === 'singles' }" @click="$emit('select', 'singles')">
            <h4>Singles</h4>
        </div>
        <div class="tab right" :class="{ active: selected === 'parlay' }" @click="$emit('select', 'parlay')">
            <h4>Parlay</h4>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Tabs',
        props: {
            selected: { type: String, default: 'singles' },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Tabs {

        height: size(Large);

        border-radius: 5px;

        display: flex;
        overflow: hidden;

        box-shadow: 0 0 15px color(Emperor, 0.1);

    }

    .Tabs .tab {

        width: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

    }

    .Tabs .tab:before,
    .Tabs .tab:after {
        content: '';
        position: absolute;
    }

    .Tabs .tab:before {

        top: 0;
        z-index: -1;

        width: calc(100% + (16.5685px / 2));
        height: 100%;

        background-color: color(Emperor);

        transform: skewX(-22.5deg);

        display: none;

    }

    .Tabs .tab.left:before {
        left: calc(-16.5685px / 2);
    }

    .Tabs .tab.right:before {
        right: calc(-16.5685px / 2);
    }

    .Tabs .tab:after {

        top: 50%;

        width: 6px;
        height: 6px;

        border-radius: 50%;
        background-color: color(OldGold);

        transform: translateY(-50%);

        display: none;

    }

    .Tabs .tab.left:after {
        left: size(Medium);
    }

    .Tabs .tab.right:after {
        right: size(Medium);
    }

    .Tabs .tab.active:before {
        display: block;
    }

    .Tabs .tab.active:after {
        display: block;
    }

    .Tabs .tab h4 {
        color: color(Emperor, 0.15);
        text-transform: uppercase;
    }

    .Tabs .tab.active h4 {
        color: color(OldGold);
    }

</style>

<template>
    <div class="Filters">
        <div class="bar" @click="toggle">
            <h4>Filter By</h4>
            <h4 class="selected">{{ selected }}</h4>
        </div>
        <transition
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <div class="panel" v-if="isOpen">
                <div class="header">
                    <h4>Filter By</h4>
                    <Close @onClick="toggle" />
                </div>
                <div class="filters">
                    <div class="filter" v-for="filter in filters" :key="filter" :class="{ selected: filter === selected }" @click="select(filter)">
                        <h4>{{ filter }}</h4>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    // Modules.
    import gsap from 'gsap';

    // Components.
    import Close from '@/components/Close.vue';

    export default {
        name: 'Filters',
        components: {
            Close,
        },
        data() {
            return {
                isOpen: false,
            }
        },
        props: {
            filters: Array,
            selected: { type: String, required: true },
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
            select(filter) {
                this.toggle();
                this.$emit('select', filter);
            },
            enter(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.fromTo(el, { y: height }, { y: 0, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            },
            leave(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.to(el, { y: height, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Filters {

        position: relative;
        width: 100%;

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .bar {

        padding: 0 size(Medium);
        height: size(Large);

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    h4 {
        text-transform: uppercase;
    }

    .bar .selected {
        opacity: 0.25;
    }

    .panel {

        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        padding: size(Medium) size(Medium) size(Small);

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .header {

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: size(Medium);

        border-bottom: 1px solid color(Emperor, 0.15);

    }

    .filters {

        max-height: 50vh;
        padding-top: size(Small);

        display: flex;
        flex-wrap: wrap;

        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-mask-image: linear-gradient(to bottom, black calc(100% - #{size(Small)}), transparent);

    }

    .filter {
        width: 50%;
        padding: size(Small) size(Medium) size(Small) 0;
    }

    .filter.selected h4 {
        color: color(OldGold);
    }

</style>

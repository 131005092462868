<template>
    <div class="SingleTicket">
        <div class="card" ref="card">
            <div class="header">
                <div>
                    <h3>{{ bet.wager[1].name }}</h3>
                    <p class="footnote">{{ bet.wager[1].variant }}</p>
                    <p class="footnote course" v-if="bet.wager[1].course">{{ bet.wager[1].course }}</p>
                </div>
                <h4 class="bumper">{{ convertDate(bet.wager[1].startTime) }}</h4>
            </div>
            <div class="line">
                <h4>{{ bet.wager[1].lines[bet.selected] }}</h4>
                <h4 class="odds">{{ bet.wager[1].odds[bet.selected].toFixed(2) }}</h4>
            </div>
            <div class="inputs">
                <div class="risk">
                    <p class="footnote">Risk</p>
                    <input type="number" v-model="inputVal" :placeholder="`MAX $${ bet.wager[1].max.toFixed(2) }`" min="1" :max="bet.wager[1].max">
                </div>
                <div class="win">
                    <p class="footnote">To Win</p>
                    <input type="text" disabled :value="inputVal && ((inputVal * bet.wager[1].odds[bet.selected]) - inputVal).toFixed(2) || ''">
                </div>
            </div>
        </div>
        <div class="delete" :class="{ hide: position !== 'left' }" @click="$emit('delete')">
            <img src="/images/delete.svg" alt="Delete" />
        </div>
    </div>
</template>

<script>

    // Modules.
    import Hammer from 'hammerjs';
    import gsap from 'gsap';

    // Misc.
    import { convertDate } from '@/utils.js';

    export default {
        name: 'SingleTicket',
        props: {
            bet: { type: Object, required: true },
            value: String,
        },
        data() {
            return {
                position: 'center',
            }
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        methods: {
            convertDate(timestamp) {
                return convertDate(timestamp);
            },
        },
        mounted() {

            const hammertime = new Hammer(this.$refs.card);

            hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
            hammertime.on('swipe', e => {

                if (e.distance > 75) {
                    switch (true) {
                        // case (e.direction === 4 && this.position === 'center' && this.$props.lock !== 'right'):
                        //     gsap.to(this.$refs.swiper, { x: 50, duration: 0.5, ease: 'expo.out' });
                        //     this.position = 'right';
                        //     break;
                        case (e.direction === 4 && this.position === 'left'):
                        // case (e.direction === 2 && this.position === 'right'):
                            gsap.to(this.$refs.card, { x: 0, duration: 0.5, ease: 'expo.out' });
                            this.position = 'center';
                            break;
                        case (e.direction === 2 && this.position === 'center'):
                            gsap.to(this.$refs.card, { x: -50, duration: 0.5, ease: 'expo.out' });
                            this.position = 'left';
                            break;
                    }
                }

            });

        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .SingleTicket {
        position: relative;
    }

    .card {

        width: 100%;
        padding: size(Medium);
        margin-bottom: size(Small);

        position: relative;
        z-index: 5;

    }

    .header {

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid color(Emperor, 0.15);

        padding-bottom: size(Medium);
        margin-bottom: size(Medium);

    }

    .header h4 {

        white-space: pre;
        text-align: right;
        line-height: 1.25;

        opacity: 0.25;

    }

    .header p.footnote {
        font-weight: 700;
        // margin-top: 2px;
    }

    .header p.footnote:first-of-type {
        margin-top: size(Micro);
    }

    .line {

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: size(Medium);

    }

    .line .odds {
        color: color(OldGold);
    }

    .inputs {

        display: flex;

        border-radius: 5px;
        border: 1px solid color(Emperor, 0.15);

    }

    .inputs .risk,
    .inputs .win {

        width: 50%;
        padding: 0 size(Small);

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .inputs .win {
        background-color: color(WildSand);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .inputs p.footnote {
        white-space: nowrap;
        text-transform: uppercase;
    }

    .inputs input {

        box-shadow: none;
        text-align: right;

        border-radius: 0;

        padding: 0;

    }

    .inputs input:focus {
        border: none;
    }

    .inputs input:disabled {
        opacity: 1;
        background-color: color(WildSand);
    }

    .delete {

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        right: 0;

        width: size(Large);
        height: 100%;

        border-radius: 5px;
        box-shadow: 0 0 15px color(Black, 0.1);

        background-color: color(Carmine);

        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

    }

    .delete.hide {
        opacity: 0;
        visibility: hidden;
    }

    .delete img {
        width: 20px;
    }

</style>

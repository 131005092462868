<template>
    <div class="ParlayTicket">
        <div class="card" ref="card">
            <div :key="`${ bet.wager[0] }${ bet.selected }`" class="bet" v-for="bet in betSlip">
                <div class="header">
                    <div>
                        <h3>{{ bet.wager[1].name }}</h3>
                        <p class="footnote">{{ bet.wager[1].variant }}</p>
                        <p class="footnote course" v-if="bet.wager[1].course">{{ bet.wager[1].course }}</p>
                    </div>
                    <h4 class="bumper">{{ convertDate(bet.wager[1].startTime) }}</h4>
                </div>
                <div class="line">
                    <h4>{{ bet.wager[1].lines[bet.selected] }}</h4>
                    <h4 class="odds">{{ bet.wager[1].odds[bet.selected].toFixed(2) }}</h4>
                </div>
            </div>
            <div class="tally">
                <div class="line">
                    <h4>Parlay ({{ betSlip.length }} Picks)</h4>
                    <h4 class="odds">{{ odds.toFixed(2) }}</h4>
                </div>
                <div class="inputs">
                    <div class="risk">
                        <p class="footnote">Risk</p>
                        <input :max="max" :placeholder="`MAX $${ max.toFixed(2) }`" min="1" type="number" v-model="inputVal">
                    </div>
                    <div class="win">
                        <p class="footnote">To Win</p>
                        <input type="text" disabled :value="win">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // Misc.
    import { convertDate } from '@/utils.js';

    export default {
        name: 'ParlayTicket',
        props: {
            betSlip: { type: Array, required: true },
            value: String,
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            },
            max() {
                return Math.min.apply(Math, this.betSlip.map(bet => bet.wager[1].max));
            },
            odds() {

                let odds = 1;
                this.betSlip.map(bet => { odds *= bet.wager[1].odds[bet.selected] });

                return odds;

            },
            win() {

                if (this.inputVal) {
                    return ((this.inputVal * this.odds) - this.inputVal).toFixed(2);
                }

                return '';

            }
        },
        methods: {
            convertDate(timestamp) {
                return convertDate(timestamp);
            },
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .card {

        width: 100%;
        margin-top: size(Small);

        position: relative;
        z-index: 5;

    }

    .bet {
        padding: size(Medium) size(Medium) 0;
        border-bottom: 1px dashed color(Emperor, 0.15);
    }

    .header {

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid color(Emperor, 0.15);

        padding-bottom: size(Medium);
        margin-bottom: size(Medium);

    }

    .header h4 {

        white-space: pre;
        text-align: right;
        line-height: 1.25;

        opacity: 0.25;

    }

    .header p.footnote {
        font-weight: 700;
        // margin-top: 2px;
    }

    .header p.footnote:first-of-type {
        margin-top: size(Micro);
    }

    .line {

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: size(Medium);

    }

    .line .odds {
        color: color(OldGold);
    }

    .tally {
        padding: size(Medium);
    }

    .inputs {

        display: flex;

        border-radius: 5px;
        border: 1px solid color(Emperor, 0.15);

    }

    .inputs .risk,
    .inputs .win {

        width: 50%;
        padding: 0 size(Small);

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .inputs .win {
        background-color: color(WildSand);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .inputs p.footnote {
        white-space: nowrap;
        text-transform: uppercase;
    }

    .inputs input {

        box-shadow: none;
        text-align: right;

        border-radius: 0;

        padding: 0;

    }

    .inputs input:focus {
        border: none;
    }

    .inputs input:disabled {
        opacity: 1;
        background-color: color(WildSand);
    }

    .delete {

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        right: 0;

        width: size(Large);
        height: 100%;

        border-radius: 5px;
        box-shadow: 0 0 15px color(Black, 0.1);

        background-color: color(Carmine);

        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

    }

    .delete.hide {
        opacity: 0;
        visibility: hidden;
    }

    .delete img {
        width: 20px;
    }

</style>

<template>
    <section id="Books">
        <BookSelector :books="books" :selectedName="profile.books[$route.params.book_id] && profile.books[$route.params.book_id].name" showWagerCount />
        <div class="wager" v-for="wager in filteredWagers" :key="wager[0]">
            <div class="header">
                <div>
                    <h3>{{ wager[1].name }}</h3>
                    <p class="footnote">{{ wager[1].variant }}</p>
                    <p class="footnote course" v-if="wager[1].course">{{ wager[1].course }}</p>
                </div>
                <h4 class="bumper date" v-if="wager[1].status !== 'suspended'">{{ convertDate(wager[1].startTime) }}</h4>
                <h4 class="bumper" v-if="wager[1].status === 'suspended'"><Status type="suspended" /></h4>
            </div>
            <span v-if="wager[1].type === 'future'" :class="{ disabled: wager[1].status === 'suspended' }">
                <div class="line" v-for="line in sortFuture(wager[1])" :key="line.line" @click="addToSlip(wager, line.index)">
                    <h4>{{ line.line }}</h4>
                    <h4 class="odds">{{ line.odds.toFixed(2) }}</h4>
                </div>
            </span>
            <span v-else :class="{ disabled: wager[1].status === 'suspended' }">
                <div class="line" v-for="(line, index) in wager[1].lines" :key="line" @click="addToSlip(wager, index)">
                    <h4>{{ line }}</h4>
                    <h4 class="odds">{{ wager[1].odds[index].toFixed(2) }}</h4>
                </div>
            </span>
        </div>
        <h3 class="no-results" v-if="Object.keys(filteredWagers).length === 0">No wagers available.</h3>
        <div class="bet-slip-cta" v-if="betSlip.length > 0" @click="isCreatingTicket = true">
            <h4 class="icon">{{ betSlip.length }}</h4>
            <h4 class="text">Bet Slip</h4>
        </div>
        <transition
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <div class="bet-slip" v-if="isCreatingTicket">
                <div class="header">
                    <h4 class="icon">{{ betSlip.length }}</h4>
                    <Close @onClick="isCreatingTicket = false; success = false;" />
                    <h4 class="text">Bet Slip</h4>
                </div>
                <h3 class="warning" v-if="betSlip.length === 0 && !success">Your bet slip is empty.</h3>
                <div class="ticket-builder" v-if="betSlip.length > 0 && !success">
                    <form @submit="placeBet">
                        <Tabs :selected="ticketMode" @select="updateTicketMode" v-if="betSlip.length > 1" />
                        <div v-if="ticketMode === 'singles'" class="singles">
                            <SingleTicket v-for="(bet, index) in betSlip" :key="`${ bet.wager[0] }${ bet.selected }`" :bet="bet" v-model="bets[index]" @delete="removeBet(index)" />
                        </div>
                        <div v-if="ticketMode === 'parlay'" class="parlay">
                            <ParlayTicket :betSlip="betSlip" v-model="bets[0]" />
                        </div>
                        <p class="error" v-if="error">{{ error }}</p>
                        <div class="buttons">
                            <Button arrow :disabled="!canPlaceBet">Place Bet(s)</Button>
                        </div>
                    </form>
                    <div class="clear-button">
                        <Button theme="red" @onClick="clearTicket">Clear Ticket</Button>
                        <p class="footnote">Pressing this button will remove all selections from the current bet slip. Swipe left on single bets to remove them from the ticket one at a time.</p>
                    </div>
                </div>
                <div class="card card--form" v-if="success">
                    <h3>Thank You!</h3>
                    <p>Your bet(s) have been placed successfully and your bet slip has been cleared. Press the button below or close the bet slip to continue.</p>
                    <Button arrow @onClick="isCreatingTicket = false; success = false;">Return</Button>
                </div>
            </div>
        </transition>
        <Filters v-if="Object.keys(filteredWagers).length > 0" :filters="tags" :selected="filter" @select="changeFilter" />
    </section>
</template>

<script>

    // Modules.
    import gsap from 'gsap';

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Filters from '@/components/Filters.vue';
    import Close from '@/components/Close.vue';
    import Tabs from '@/components/Tabs.vue';
    import Button from '@/components/Button.vue';
    import SingleTicket from '@/components/SingleTicket.vue';
    import ParlayTicket from '@/components/ParlayTicket.vue';
    import Status from '@/components/Status.vue';

    // Firebase.
    import { watchBook } from '@/firebase/books.js';
    import { watchWager, createBet } from '@/firebase/wagers.js';

    // Misc.
    import { convertDate } from '@/utils.js';

    export default {
        name: 'Books',
        components: {
            BookSelector,
            Filters,
            Close,
            Tabs,
            Button,
            SingleTicket,
            ParlayTicket,
            Status,
        },
        data() {
            return {
                wagers: {},
                filter: 'ALL',
                betSlip: [],
                bets: [],
                isCreatingTicket: false,
                ticketMode: 'singles',
                success: false,
                error: null,
            }
        },
        computed: {
            books() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin' || book[1].membership === 'member');
            },
            tags() {

                let tags = ['ALL'];
                const now = Date.now();

                for (let wager of Object.entries(this.wagers)) {
                    if (wager[1].tags && wager[1].startTime > now) {
                        tags = [...tags, ...wager[1].tags];
                    }
                }

                return [...new Set(tags)];

            },
            filteredWagers() {

                const now = Date.now();

                return Object.entries(this.wagers).filter(wager => {

                    if (wager[1].tags) {
                        return (wager[1].tags.includes(this.filter) || this.filter === 'ALL') && wager[1].startTime > now;
                    }

                    return this.filter === 'ALL' && wager[1].startTime > now;

                }).sort((a, b) => a[1].startTime - b[1].startTime);

            },
            canPlaceBet() {
                if (this.ticketMode === 'singles') {
                    return (this.betSlip.length === this.bets.filter(bet => bet && bet >= 1).length) && this.betSlip.length > 0;
                } else if (this.ticketMode === 'parlay') {
                    return (this.bets[0] && this.bets[0] >= 1);
                }
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        created() {
            if (this.$route.params.book_id === '0') this.$router.replace({ name: 'Home' });
            else {
                this.$store.dispatch('updateLoading', 1);

                const membership = this.profile.books[this.$route.params.book_id].membership;

                if (membership === 'member' || membership === 'admin' || membership === 'bookie') {
                    watchBook(this.$route.params.book_id).on('value', this.listen);
                }

            }
        },
        watch: {
            $route(to, from) {
                if (to.params.book_id === '0') this.$router.replace({ name: 'Home' });
                else {
                    this.$store.dispatch('updateLoading', 1);
                    watchBook(from.params.book_id).off('value', this.listen);
                    watchBook(to.params.book_id).on('value', this.listen);
                }
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            listen(snapshot) {

                const val = snapshot.val();
                this.wagers = {};

                if (val.wagers) {
                    for (let wager of Object.keys(val.wagers)) {
                        this.$store.dispatch('updateLoading', 1);
                        watchWager(wager).on('value', this.watchWager);
                    }
                }

                this.$store.dispatch('updateLoading', -1);

            },
            watchWager(snapshot) {

                const key = snapshot.key;
                const val = snapshot.val();

                this.wagers = { ...this.wagers, [key]: val };

                this.$store.dispatch('updateLoading', -1);

            },
            convertDate(timestamp) {
                return convertDate(timestamp);
            },
            changeFilter(filter) {
                this.filter = filter;
            },
            addToSlip(wager, selected) {

                for (let bet of this.betSlip) {
                    // Duplicate.
                    if (bet.wager[0] === wager[0] && bet.selected === selected) return;
                }

                this.betSlip.push({ wager, selected });

            },
            updateTicketMode(ticketMode) {
                this.bets = [];
                this.ticketMode = ticketMode;
            },
            async placeBet(e) {

                e.preventDefault();

                this.$store.dispatch('updateLoading', 1);

                let error = false;
                this.error = null;

                // Secondary failover for schemers.
                for (let bet of this.betSlip.values()) {
                    if (Date.now() > bet.wager[1].startTime) {
                        error = true;
                    }
                }

                if (error) {
                    this.error = 'One or more wagers is no longer available.';
                    this.$store.dispatch('updateLoading', -1);
                    return;
                }

                if (this.ticketMode === 'singles') {
                    for (let [index, bet] of this.betSlip.entries()) {
                        await createBet({
                            bets: [bet],
                            risk: parseFloat(this.bets[index]),
                        }, this.user.uid, this.$route.params.book_id);
                    }
                } else {
                    await createBet({
                        bets: this.betSlip,
                        risk: parseFloat(this.bets[0])
                    }, this.user.uid, this.$route.params.book_id);
                }

                this.betPlaced();

            },
            betPlaced() {
                this.success = true;
                this.clearTicket();
                this.$store.dispatch('updateLoading', -1);
            },
            clearTicket() {
                this.bets = [];
                this.betSlip = [];
            },
            removeBet(index) {
                this.betSlip.splice(index, 1);
                this.bets.splice(index, 1);
            },
            sortFuture(wager) {

                let lines = [];

                for (let i = 0; i < wager.lines.length; i++) {
                    lines.push({
                        line: wager.lines[i],
                        odds: wager.odds[i],
                        index: i,
                    });
                }

                lines = lines.sort((a, b) => a.odds - b.odds);

                return lines;

            },
            enter(el, done) {
                // const height = el.getBoundingClientRect().height
                gsap.fromTo(el, { y: '100%' }, { y: '0%', duration: 0.75, ease: 'power3.inOut', onComplete: done });
            },
            leave(el, done) {
                // const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.to(el, { y: '100%', duration: 0.75, ease: 'power3.inOut', onComplete: done });
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Books {
        overflow: hidden;
        padding: size(Large) 0 size(XXLarge);
    }

    .no-results {
        text-align: center;
        margin-top: size(Medium);
    }

    .wager .header {

        padding: size(Medium);
        background-color: color(WildSand);

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid color(Emperor, 0.15);

    }

    .wager .header h3 {
        text-align: left;
    }

    .wager .header h4 {
        white-space: pre;
        text-align: right;
        line-height: 1.25;
    }

    .wager .header h4.date {
        opacity: 0.25;
    }

    .wager .header p.footnote {
        font-weight: 700;
        // margin-top: 2px;
    }

    .wager .header p.footnote:first-of-type {
        margin-top: size(Micro);
    }

    .wager span.disabled .line {
        opacity: 0.15;
        pointer-events: none;
    }

    .wager .line {

        padding: 12px size(Medium);
        background-color: color(White);

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid color(Emperor, 0.15);

        transition: background-color 250ms ease-in-out, color 250ms ease-in-out;

    }

    .wager .line:active {
        background-color: color(Emperor);
        color: color(White);
    }

    .line .odds {
        color: color(OldGold);
    }

    .Filters {
        position: fixed !important;
        bottom: 0;
        left: 0;
        z-index: 10;
    }

    .bet-slip {

        position: fixed;
        top: 50px;
        left: 0;
        z-index: 25;

        width: 100%;
        height: calc(100% - 50px);

        background-color: color(WildSand);

        display: flex;
        flex-direction: column;

    }

    .bet-slip .header {

        position: relative;
        flex-shrink: 0;

        box-shadow: 0 0 15px color(Emperor, 0.1);

    }

    .bet-slip .header,
    .bet-slip .header h4.icon,
    .bet-slip-cta,
    .bet-slip-cta h4.icon {
        display: flex;
        align-items: center;
    }

    .bet-slip .header,
    .bet-slip-cta {

        width: 100%;
        height: size(Large);
        padding: 0 size(Medium);

        background-color: color(OldGold);
        color: color(White);

        justify-content: space-between;

    }

    .bet-slip-cta {
        position: fixed;
        bottom: size(Large);
        left: 0;
    }

    .bet-slip .header h4.icon,
    .bet-slip-cta h4.icon {

        width: 24px;
        height: 24px;

        border-radius: 50%;
        background-color: color(Emperor);

        justify-content: center;

    }

    .bet-slip .header h4.text,
    .bet-slip-cta h4.text {

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

        text-transform: uppercase;

    }

    .ticket-builder {

        padding: size(Medium);

        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        flex-grow: 1;

    }

    .ticket-builder .Tabs {
        margin-bottom: size(Medium);
    }

    .ticket-builder .buttons {
        padding: 0 size(Large);
        margin-top: size(Medium);
    }

    .ticket-builder .buttons .Button {
        margin-bottom: size(Small);
    }

    .ticket-builder .clear-button {
        padding: 0 size(Large);
    }

    .ticket-builder .clear-button p,
    .bet-slip .card--form p {
        margin-top: size(Small);
    }

    .warning {
        padding: size(Medium);
        text-align: center;
    }

    .bet-slip .card--form {
        margin-top: size(Medium);
    }

    .bet-slip .card--form .Button {
        margin-top: size(Large);
    }

    p.error {
        margin-top: size(Medium);
    }

</style>

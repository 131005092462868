// Modules.
import { v4 as uuidv4 } from 'uuid';

// Firebase.
import { database } from '@/firebase/config.js';

export async function createWager(options, bookId, wagerId) {

    const date = new Date(options.date);
    const data = {
        book: bookId,
        type: options.type,
        name: options.name,
        variant: options.variant,
        course: options.course,
        startTime: date.getTime(),
        tags: options.tags,
        total: options.total,
        lines: options.lines,
        odds: options.odds,
        max: options.max,
    };

    if (!wagerId) {
        const wager = await database.ref('wagers').push({ ...data, status: 'open' });
        return database.ref(`books/${ bookId }/wagers/${ wager.key }`).set(true);
    } else {
        const wager = (await database.ref(`wagers/${ wagerId }`).once('value')).val();
        return database.ref(`wagers/${ wagerId }`).set({ ...wager, ...data });
    }

}

export function getWager(id) {
    return database.ref(`wagers/${ id }`).once('value');
};

export function watchWager(id) {
    return database.ref(`wagers/${ id }`);
};

export function suspendWager(id) {
    return database.ref(`wagers/${ id }/status`).set('suspended');
};

export function reopenWager(id) {
    return database.ref(`wagers/${ id }/status`).set('open');
};

export async function deleteWager(bookId, wagerId) {
    await database.ref(`books/${ bookId }/wagers/${ wagerId }`).remove();
    return database.ref(`wagers/${ wagerId }`).remove();
};

export async function settleWager(id, winningLines, finalTotal) {
    await database.ref(`wagers/${ id }/status`).set('settled');
    await database.ref(`wagers/${ id }/finalTotal`).set(finalTotal);
    return database.ref(`wagers/${ id }/winningLines`).set(winningLines);
};

export async function createBet(options, userId, bookId) {

    const bet = await database.ref('bets').push({
        book: bookId,
        user: userId,
        options,
        void: false,
        createdAt: Date.now(),
    });

    for (let b of options.bets) {
        await database.ref(`wagers/${ b.wager[0] }/bets/${ bet.key }`).set(true);
    }

    await database.ref(`books/${ bookId }/bets/${ bet.key }`).set(true);
    return database.ref(`profiles/${ userId }/bets/${ bet.key }`).set(true);

};

export function getBet(id) {
    return database.ref(`bets/${ id }`).once('value');
};

export async function voidBet(senderId, recipientId, bookId, betId) {

    const alert = await database.ref('alerts').push({
        type: 'void',
        createdAt: Date.now(),
        recipient: recipientId,
        sender: senderId,
        book: bookId,
    });

    await database.ref(`profiles/${ recipientId }/alerts/${ alert.key }`).set({ read: false });
    return database.ref(`bets/${ betId }/void`).set(true);

}
